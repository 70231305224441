<template>
    <div class="HomeBox">
        <div class="SwipeBox">
            <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
                <van-swipe-item v-for="(item, index) in Banner" :key="index"> <img :src="item.Code_Name" alt=""> </van-swipe-item>
             
            </van-swipe>
        </div>
         <van-notice-bar left-icon="volume-o" :scrollable="false">
            <van-swipe
                vertical
                class="notice-swipe"
                :autoplay="3000"
                :show-indicators="false" >
                <van-swipe-item v-for="(item, index) in PublicColumn" :key="index">{{item.Code_Name}}</van-swipe-item>
            </van-swipe>
        </van-notice-bar>
        <van-row class="Gonggao">
             <van-col span="24" class="GonggaoBox">
                <div  class="Title fl">系统公告</div>
                <div class="GaongaoConte txt-cut3 ">尊敬的用户，您好!实验室线上预约功能已经上线，大家可以通过当前系统进行实验室线上预约服务。</div>
             </van-col>
          
        </van-row>
       
        <div class="MuenBox">
            <van-grid :column-num="3" clickable icon-size='30px'>
                <van-grid-item   to="/laboratoryList" icon="todo-list-o" text="实验室概览" />
                <van-grid-item   :to="{path:'/makeAppointment/laboratoryInfo',query:{fromPath:encodeURI($route.path)}}" icon="todo-list-o" text="线上预约" />
                <van-grid-item   to="/queryMake" icon="newspaper-o" text="预约查询" />
                <van-grid-item   to="/danger" icon="fire-o" text="危险品实验申请" />
                <van-grid-item   to="/user/userInfo" icon="friends-o" text="个人信息" />
            </van-grid>
        </div>
    </div>
</template>
<script>
import { Swipe, SwipeItem,Grid, GridItem ,NoticeBar,Col, Row} from 'vant';
import {Api_GetCodeByTypes} from '@/api/home'
export default {
     components: {
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
        [NoticeBar.name]: NoticeBar,
        [Col.name]: Col,
        [Row.name]: Row,
    },
    data() {
        return {
            PublicColumn:[],
            Banner:[]
        }
    },
    created(){
        this.GetList()
    },
    methods:{
        GetList(){
            Api_GetCodeByTypes({
                type:'PublicColumn-Banner'
            }).then(res=>{
                this.PublicColumn = res.PublicColumn
                this.Banner = res.Banner
            })
        }
    }
}
</script>
<style lang="scss" scoped>
    .HomeBox {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        .SwipeBox {
            height: 200px;
            .my-swipe {
                .van-swipe-item {
                    color: #fff;
                    font-size: 20px;
                    height: 200px;
                    text-align: center;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
        .MuenBox {
            flex: 1;
            background: #fff;
            /deep/ .van-icon {
                padding: 3px 0px;
                color: #409EFF;
            }
        }
        .van-notice-bar {
            height: 32px;
            line-height: 32px;
        }
        .notice-swipe {
            height: 32px;
            line-height: 32px;
        }
        .Gonggao {
            background: #f0f0f0;
        
        .GonggaoBox {
            display: flex;
            flex-direction: row;
            align-items: center;
             margin: 8px 0px;
            justify-content: center;
           
        }
        .Title {
                color: #333;
                height: 80px;
                background: #fff;
                -webkit-writing-mode: vertical-lr;
                -ms-writing-mode: tb-lr;
                writing-mode: vertical-lr;
                -webkit-writing-mode: tb-lr;
                writing-mode: tb-lr;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                letter-spacing: 5px;
                padding: 8px 15px;
        }
            .van-col {
                background: #fff;
            }
        .GaongaoConte {
                background: #fff;
                color: #333;
                line-height: 25px;
               
                height: 80px;
                font-size: 13px;
                letter-spacing: 1px;  
                padding: 8px 0px;
                padding-right: 10px;
            } 
        
            .CuntBox {
                font-size: 13px;
                line-height: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 120px;
                position: relative;
            }
            .CuntBox ::after {
            content:"";
            width: 2PX;
            height: 90px;
            background: #e6e6e6;
            position: absolute;
            top:50%;
            transform: translateY(-50%);
            left: 9px;
            }
        }
    }
</style>